import React from 'react'

import useHelpLink from 'lib/hooks/useHelpLink'
import useTranslation from 'lib/hooks/useTranslation'

import { TNamespaces } from 'lib/@Types/translation'

import s from './styles.module.scss'

type Question = {
  question: string
  url: string
  ns?: TNamespaces
}

const questions: Question[] = [
  {
    question: 'q.pelagoShopBackPromotionFaqs',
    url: '/articles/13451557263897-Pelago-x-ShopBack-Promotion-FAQs',
  },
  {
    question: 'q.pelagoPassSingaporeFaqs',
    url: '/articles/13036060888473-Singapore-Attractions-Pass-FAQs',
  },
  {
    question: 'q.howDoIApplyPromotionalCodeWhenBooking',
    url: '/articles/9437885744793-How-do-I-apply-a-promotional-code-when-booking-',
    ns: 'booking',
  },
  {
    question: 'q.howDoIChangeActivityDateTimeOfMyBooking',
    url: '/articles/9421420421529-How-do-I-change-the-Activity-Date-Time-of-my-booking-',
    ns: 'booking',
  },
  {
    question: 'q.howDoICancelMyBooking',
    url: '/articles/900002311586-How-do-I-cancel-my-booking-',
    ns: 'booking',
  },
  {
    question: 'q.whatIsPelagoCancellationPolicy',
    url: '/articles/900002296183-What-is-Pelago-s-Cancellation-Policy-',
    ns: 'booking',
  },
  {
    question: 'q.formula1SingaporeGrandPrixFaqs',
    url: '/articles/5796329840025-Formula-1-Singapore-Grand-Prix-FAQs',
  },
  {
    question: 'q.iCannotFindMyBookingConfirmationEticketVouchers',
    url: '/articles/900002311086-I-cannot-find-my-booking-confirmation-e-tickets-vouchers',
    ns: 'booking',
  },
]

const Faqs = () => {
  const { t } = useTranslation('common')
  const helpCenterUrl = useHelpLink()

  return (
    <ul className={s.faqList}>
      {questions.map((q) => {
        const { question, url, ns } = q
        return (
          <a key={question} href={helpCenterUrl + url} rel="noopener noreferrer">
            {t(question, { ns: ns || 'common' })}
          </a>
        )
      })}
    </ul>
  )
}

export default Faqs
